import auth from './modules/auth';
import center from './modules/center';

export default {
  ...center,
  ...auth,
  global: {
    supportEmail: 'ayuda@timejobs.work',
    firstLevel: 'Región',
    secondLevel: 'Comuna',
    secondLevel_plural: 'Comunas',
    firstLevel_gender: 'female',
    secondLevel_gender: 'female',
    drivingLicence: 'Licencia de conducir *',
  },
};
