/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { NextComponentType, NextPageContext } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { hotjar } from 'react-hotjar';

//! Redux
import { Provider } from 'react-redux';
import store from '@store/index';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

//! Translator
import { I18nextProvider } from 'react-i18next';

import { ConfigProvider, Grid } from 'antd';
import es from 'antd/lib/locale/es_ES';
import { createImageBitmapIIFE, loadIntercom } from '@utils/functions';

// eslint-disable-next-line import/extensions
import { version } from '../package.json';
import I18n from '../src/i18n/i18n';
import '@styles/theme/antd.less';
import moment from 'moment';

const { useBreakpoint } = Grid;

const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

const persistor = persistStore(store);

interface AppProps {
  path: string;
  pageProps: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  Component: NextComponentType<NextPageContext, any, {}> & { layoutProps: any };
}

moment.locale('es');

function MyApp({ Component, pageProps, path }: AppProps) {
  const screens = useBreakpoint();
  const route = useRouter();
  const [_id, setID] = useState('');
  const state: any = store.getState();

  useEffect(() => {
    if (typeof analytics !== 'undefined') analytics.page();
    if (!_id && state.auth?.user?._id) {
      setID(state.auth.user._id);
    }
  }, [route.pathname]);

  useEffect(() => {
    createImageBitmapIIFE(window);
    loadIntercom({
      app_id: process.env.NEXT_PUBLIC_INTERCOM_KEY,
      hide_default_launcher: true,
    });
    if (isProduction) {
      hotjar.initialize(2350414, 6);
    }
  }, []);

  return (
    <>
      <Head>
        <title>Jobber Center - Time Jobs</title>
        <link rel="icon" href="/tj-mini-logo.svg" />
        <meta name="version" content={version} />
        {process.env.NEXT_PUBLIC_GTM_KEY && (
          <>
            <style jsx global>{`
              .async-hide {
                opacity: 0 !important;
              }
            `}</style>
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
                          h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
                          (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
                          })(window,document.documentElement,'async-hide','dataLayer',4000,
                          {'${process.env.NEXT_PUBLIC_GTM_KEY}':true});`,
              }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html:
                  // eslint-disable-next-line quotes
                  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_KEY}');`,
              }}
            />
            {_id && (
              <script
                dangerouslySetInnerHTML={{
                  __html:
                    // eslint-disable-next-line quotes
                    `window.dataLayer = window.dataLayer || []; window.dataLayer.push({'event' : 'authentication','userId' : '${_id}'})`,
                }}
              />
            )}
          </>
        )}
      </Head>
      {process.env.NEXT_PUBLIC_GTM_KEY && (
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_KEY}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          }}
        />
      )}
      <ConfigProvider locale={es}>
        <I18nextProvider i18n={I18n}>
          <link href="https://fonts.googleapis.com/css?family=Nunito Sans" rel="stylesheet" />
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <div className={screens.xs ? 'mobile' : 'web'}>
                <Component {...pageProps} />
              </div>
            </PersistGate>
          </Provider>
        </I18nextProvider>
      </ConfigProvider>
    </>
  );
}

export default MyApp;
