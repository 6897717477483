export default {
  auth: {
    newPswd: {
      title: 'Crear contraseña',
      subtitle:
        'Al crear tu contraseña, estarás activando tu cuenta y podrás comenzar con el proceso de registro',
      inputs: {
        pswd: {
          lessThan8Char: 'La contraseña debe ser de al menos 8 caracteres',
        },
        confirmPswd: {
          txt: 'Confirmar contraseña',
          neq: 'Contraseñas no coinciden',
        },
      },
      button: {
        txt: 'Crear contraseña',
      },
      alert: {
        success:
          'Tu contraseña se ha creado exitosamente. Serás redirigido a tu cuenta para completar tus datos',
      },
      alreadyActive: {
        title: 'Tu cuenta ya está activada',
        text: 'Para ingresar haz click en el botón',
        button: 'Ir a Log In',
      },
      notFound: {
        title: 'Tu link de ingreso ha expirado',
        text: 'Para solicitar uno nuevo, ingresa tu email y haz click en el botón',
        button: 'Reenviar link',
        success: 'El link se ha enviado exitosamente. Revisa tu bandeja de entrada o spam',
        error: 'Email no se encuentra registrado',
      },
    },
    confirmEmail: {
      title: 'Confirma tu email',
      subtitle: 'Hemos enviado un correo a la siguiente dirección:',
      body1:
        'Revisa tu bandeja de entrada o spam para verificar tu cuenta. Si el email que aparece aquí no es el tuyo, puedes cambiarlo.',
      body2:
        'Si no has recibido el correo, reenvíalo a tu cuenta con el botón que aparece más abajo. En caso de tener algún problema escríbenos a ayuda@timejobs.work',
      buttons: {
        editButton: 'Cambiar email',
        changeButton: 'Modificar email',
        resendButton: 'Reenviar correo',
        wait: 'Reenviar correo en 30 segundos',
        cancel: 'Cancelar',
      },
      successMsj:
        'Correo reenviado exitosamente. Asegúrate de revisar tu bandeja de entrada o spam.',
      errorMsj: 'El email que ingresaste ya tiene una cuenta. Intenta con uno diferente.',
      errorMsj404:
        'El email actual que intentas cambiar no existe o ya se encuentra confirmado. Si no recuerdas la clave puedes recuperarla en ',
      invalidEmail: 'Ingresa un email válido',
    },
    signUp: {
      acceptedTaCError: 'Debes aceptar los términos y condiciones',
      alertEmail: 'Ingresa un email válido',
      alertInputNames: 'Tu nombre no puede contener números ni caracteres extraños',
      alertInputSurnames: 'Tu apellido no puede contener números ni caracteres extraños',
      alertMaxLength: 'No puedes exceder los 50 caracteres',
      button: 'Regístrate',
      confirmEmail: 'Confirma tu email',
      countryIso: 'País',
      email: 'Email',
      errorCompanyEmail:
        'Tu email Time Jobs está reservado para uso interno, por favor intenta con otro',
      errorConfirmEmail: 'Los emails ingresados no coinciden',
      errorEmail: 'El correo ya se encuentra registrado',
      errorRequest:
        'Hubo un error intentando enviar la información. Por favor contáctate con nuestro equipo a través del correo $t(global.supportEmail) para poder ayudarte.',
      inputEmail: 'Ingresa tu email',
      inputNames: 'Ingresa tu nombre',
      inputSurnames: 'Ingresa tu apellido',
      locality: 'Comuna',
      localityLow:
        'Por el momento este $t(global.secondLevel, lowercase) se encuentra con baja disponibilidad de tareas, de todas formas te invitamos a realizar tu registro y te iremos avisando a tu email cuando tengamos novedades para que puedas seguir avanzando',
      names: 'Nombre',
      PEterms: ['Acepto la ', 'ley de protección de datos', ' y '],
      region: 'Región',
      surnames: 'Apellido',
      terms: ['Acepto los ', 'términos y condiciones', '. Declaro que soy mayor de 18 años.'],
      title: 'Regístrate como Jobber',
      phone: {
        label: 'Teléfono',
        required: 'Ingresa tu número de teléfono',
        errorPattern: 'Ingresa un teléfono de {{length}} dígitos',
      },
      vertical: {
        label: 'Tipos de tarea de preferencia',
      },
    },
    retrievePswd: {
      title: 'Crear nueva contraseña',
      subtitle: 'Crea tu nueva contraseña para iniciar sesión',
      button: {
        txt: 'Crear contraseña',
      },
      alert: {
        success:
          'Tu contraseña se ha creado exitosamente. Serás redirigido al login para iniciar sesión.',
      },
      retrieve: {
        title: 'Olvidé mi contraseña',
        text: 'Ingresa tu email y te enviaremos un correo para crear una nueva contraseña',
        button: 'Enviar correo',
        success: 'Correo enviado exitosamente. Revisa tu bandeja de entrada o spam',
        error: 'Email no se encuentra registrado',
      },
      notFound: {
        title: 'Tu link de recuperación de contraseña ha expirado',
        text: 'Para solicitar uno nuevo, ingresa tu email y haz click en el botón',
        button: 'Reenviar link',
        success: 'El link se ha enviado exitosamente. Revisa tu bandeja de entrada o spam',
        error: 'Email no se encuentra registrado',
      },
    },
    login: {
      title: 'Iniciar sesión',
      text: 'Ingresa tu email y contraseña para acceder a tu cuenta',
      psw: 'Olvidé mi contraseña',
      notRegistered: ['¿No tienes una cuenta?', 'Regístrate'],
      alertBlocked:
        'Tu cuenta se encuentra bloqueada. Para mayor información contáctanos a través del email',
      alertNotFound: 'Email y/o contraseña incorrectos. Por favor intenta nuevamente',
      alertMaxLoginRetries: 'Has superado el número máximo de intentos. Por favor, intenta nuevamente en 5 minutos',
      activeUser:
        'Tu cuenta ya se encuentra activa.  Si no recuerdas tu contraseña, puedes recuperarla en cualquier momento',
      rejected: {
        title: '{{name}}, no puedes seguir con tu registro para ser jobber',
        text: 'Para más información escríbenos a {{email}}',
      },
    },
  },
};
