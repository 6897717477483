export default {
  center: {
    main: {
      title: {
        applicant: 'Registro Jobber',
        jobber: 'Perfil Jobber',
      },
      welcome: {
        title: 'Bienvenid@ {{name}}!',
        text:
          'Aquí comienza tu proceso de registro, en el cual tendrás que llenar los datos que te pediremos para validar tu perfil y poder acceder a tus inducciones',
      },
      allInfoFilled:
        'Recibimos tu información correctamente.\nLa revisaremos y, una vez validada, te enviaremos un correo para continuar con tu registro. Recuerda mantenerte atento a tu email.',
      dataCompleted: 'Los datos se han completado exitosamente.',
      dataUpdated: 'Los datos se han actualizado exitosamente.',
      highDemand:
        'Actualmente tu zona se encuentra con baja demanda de tareas, por lo que podría causar un retraso en la revisión de tu información. Te avisaremos a través de email cuando esté todo listo.\n\nTermina de completar tus datos y podrás acceder a turnos en otros $t(global.secondLevel_plural, lowercase)/zonas. ¿Tienes dudas? Puedes hacer tus consultas en “página help center” o escríbenos a $t(global.supportEmail)',
      carousel: {
        1: 'Los pagos se realizarán la semana siguiente a la trabajada (día viernes).',
        2: 'Aún no podrás ver las tareas disponibles, ya que debes realizar las inducciones primero. Mantente atento, ya que las inducciones son por tiempo limitado.',
        3: 'Todos los postulantes tendrán la misma oportunidad, si no eres seleccionado no te desanimes y postula a la próxima tarea.',
        4: 'Tú manejas tu tiempo, la única regla es que si postulas a una tarea y eres seleccionado debes asistir a ella.',
        calendarImage: '/calendar_friday.svg',
        availableJobs: {
          title: 'Estás muy cerca de generar ingresos increíbles',
          subtitle: 'Completa tu registro y podrás tomar la tarea que quieras.',
          amountType: 'Valores expresados en montos {{type}}',
        },
      },
      remainingSteps: {
        title: 'Datos pendientes',
        text: 'Recuerda completar todos los datos para acceder a tus inducciones',
        links: {
          setInfo: 'Ingresar información',
          uploadPic: 'Cargar imagen',
          noInfo: 'Ingresar información',
          indications: 'Ver indicaciones',
        },
        messages: {
          noInfo: 'Aún no ingresas información',
          noSign: 'Documento pendiente de firma',
          invalidPic: 'Foto  inválida',
          in_review: 'Revisión Time Jobs',
          inReview: '{{number}} Revisión Time Jobs',
          pending: '{{number}} pendiente(s)',
          approved: 'Aprobado',
          completed: 'Completado',
          objected: '{{number}} inválido(s)',
          rejected: '{{number}} inválidos',
          inProcess: {
            in_review: '{{number}} Revisión Time Jobs',
            pending: '{{number}} pendiente(s)',
            approved: 'Aprobado',
            completed: 'Completado',
            objected: '{{number}} inválido(s)',
          },
        },
        tags: {
          approved: '{{count}} doc. aprobado',
          approved_plural: '{{count}} docs. aprobados',
          expired: '{{count}} doc. vencido',
          expired_plural: '{{count}} docs. vencidos',
          in_review: '{{count}} doc. revisión',
          in_review_plural: '{{count}} docs. revisión',
          objected: '{{count}} doc. inválido',
          objected_plural: '{{count}} docs. inválidos',
          pending: '{{count}} doc. pendiente',
          pending_plural: '{{count}} docs. pendientes',
          rejected: '{{count}} doc. rechazado',
          rejected_plural: '{{count}} docs. rechazados',
          toExpire: '{{count}} doc. por vencer',
          toExpire_plural: '{{count}} docs. por vencer',
        },
        expiration: {
          alerts: {
            advice: '{{prev}}. Actualízalo para seguir postulando a tareas.',
            advice_plural: '{{prev}}. Actualízalos para seguir postulando a tareas.',
          },
        },
        steps: {
          personalInfo: 'Datos personales',
          bankInfo: 'Datos bancarios',
          otherInfo: 'Vehículo y dispositivo',
          recordsInfo: 'Antecedentes',
          otherDocs: 'Documentos',
          profilePic: 'Foto de perfil',
          frameworkAgreement: 'Acuerdo marco',
          additionalInfo: 'Jobber Pack y documentos vehículo',
        },
      },
      doneSteps: {
        title: 'Datos completados',
        text: 'Mantén actualizada tu información',
        link: { applicant: 'Ver información', jobber: 'Editar' },
        messages: { done: 'Datos completos' },
        infoAlert: 'Aquí puedes revisar y editar los datos disponibles',
        warningInfoAlert:
          'Agrega tu código de exoneración de forma opcional, en la sección <strong>“Vehículo, dispositivo y Sunat”</strong>.',
      },
      pendingSteps: {
        title: 'Actualización pendiente',
        text: 'Recuerda actualizar tus documentos para continuar tomando tareas.',
      },
      advice: {
        title: {
          applicant: 'Recomendaciones',
          jobber: 'Descarga la App y mantenla siempre actualizada.',
        },
        texts: {
          applicant: [
            'Completa el registro con todos los datos requeridos. Así podrás convertirte en Jobber aún más rápido.',
            'Debes estar atento/a a tu email, ya que las comunicaciones oficiales se enviarán por medio de esta vía.',
            'Revisa constantemente las nuevas inducciones que iremos publicando.',
          ],
          jobber: [
            'Revisa constantemente las inducciones disponibles en la App.',
            'Postula a las tareas a través de la App y espera ser asignado/a.',
            'Puedes realizar todas las inducciones que desees y tendrás más oportunidades de tomar tareas.',
          ],
        },
      },
      downloadApp: {
        title: 'Acceso a inducciones y tareas',
        subtitle: 'Descarga la App e inicia sesión para ver tus inducciones',
        text:
          'Recuerda, para que puedas ver tus inducciones, debes descargar la App Time Jobs desde Google Play, App Store o AppGallery e inicia sesión en ella. Si aún no la has descargado, hazlo haciendo click acá abajo.',
      },
      productTour: [
        {
          title: 'Hemos recibido exitosamente tus datos',
          text: 'Podrás revisar y editar algunos de ellos en la sección “Perfil Jobber”',
        },
        {
          title: 'Descarga la App para ver tus inducciones',
          text:
            'Descarga la App Time Jobs desde Google Play o App Store. Inicia sesión con tu cuenta y espera que se activen las inducciones.',
        },
        {
          title: 'Realiza tus inducciones',
          text:
            'Luego de descargar e iniciar sesión en la App, se irán habilitando las inducciones que deberás realizar.',
        },
      ],
      cardDate: {
        title: 'Tienes hasta el {{date}} para actualizar tus documentos',
        subtitle: 'Ingresa las fechas de emisión o vencimiento de tus documentos pendientes.',
      },
      cardExpiration: {
        title_plural: 'Tienes {{count}} documentos por vencer',
        subtitle: 'Actualízalo para seguir postulando a tareas que lo requieran.',
        subtitle_plural: 'Actualízalos para seguir postulando a tareas que lo requieran.',
        tag: 'Doc. vence el {{date}}',
      },
      nextSteps: {
        title: '¿Qué hago ahora?',
        list: {
          downloadApp: 'Descarga la aplicación Timejobs Jobber 📲',
          completeProfile: 'Completa tu perfil 🙋🏻‍️🙋🏽‍♀️',
          prepare: 'Realiza inducciones 🤓',
          generatesIncome: 'Genera grandiosos ingresos 🤑',
        },
        recommendations: {
          title: 'Recomendaciones',
          subtitle: 'Considera estos puntos importantes.',
          list: {
            completeProfile:
              '¡Mientras antes completes tus datos, mas cerca estarás de poder realizar tareas!',
            downloadApp:
              'Descarga la aplicación y realiza inducciones para poder postular a tareas',
            postulate:
              'Postula a diferentes tareas en distintos horarios. En caso de ser asignado, asiste en el horario y dirección indicados, de lo contrario, cancela con anticipación.',
          },
        },
        modal: {
          downloadApp:
            'Descarga nuestra aplicación gratuita Timejobs Jobber, luego inicia sesión con el correo utilizado en tu registro y tu contraseña. Recuerda habilitar las notificaciones para recibir información relevante sobre tareas.',
          completeProfile:
            'Completa todos los datos y documentos solicitados al final de esta página o a través de la aplicación Timejobs Jobber.',
          prepare:
            ' ¡Aprende a cómo realizar la tarea que quieras, tan solo en unos minutos! Puedes acceder a las inducciones disponibles desde la aplicación Timejobs Jobber, en la sección “Inducciones” que se puede ver en la pantalla de inicio o en el menú dentro de “Más”.',
          generatesIncome:
            'Ahora simplemente postula a tareas desde la aplicación Timejobs Jobber, en la sección “Tareas” y comienza a generar ingresos como nunca antes 🚀.',
        },
      },
    },
    personalData: {
      title: 'Datos personales',
      text: 'Completa el formulario con los datos solicitados',
      alert:
        'Para poder seguir avanzando en el registro debes llenar todos los datos requeridos en esta categoría.',
      inputs: {
        firstName: {
          title: 'Nombre *',
          lower: 'nombre',
          formatError: 'Tu nombre no puede contener números ni caracteres especiales',
        },
        lastName: {
          title: 'Apellido *',
          lower: 'apellido',
          formatError: 'Tu apellido no puede contener números ni caracteres especiales',
        },
        email: { title: 'Email *', lower: 'email' },
        street: {
          title: 'Calle *',
          lower: 'calle',
          info: 'La dirección se utilizará para la emisión de salvoconducto',
          formatError: 'Ingresa números o letras',
        },
        streetNumber: {
          title: 'Número *',
          lower: 'número',
        },
        department: {
          title: 'Depto/Casa',
          lower: 'depto/casa',
        },
        nationality: { title: 'Nacionalidad *', lower: 'nacionalidad' },
        phoneNumber: {
          title: 'Teléfono *',
          lower: 'teléfono',
          pattern: /^(\d){10}$/,
          message: 'Ingresa un teléfono de 10 dígitos',
        },
        documentNumber: {
          title: 'Número de documento *',
          lower: 'número de documento',
          alreadyTaken:
            'Este número de documento ya se encuentra registrado, para más información comunícate con $(global.supportEmail)',
        },
        sex: { title: 'Género *', male: 'Masculino', female: 'Femenino', lower: 'género' },
        birthDate: {
          title: 'Fecha de nacimiento*',
          lower: 'fecha de nacimiento',
          error: 'Debes ser mayor de 18 años',
          info: 'Formato: dd/mm/aaaa',
          invalidFormat: 'Ingresa una fecha correcta',
        },
        docSel: {
          title: 'Tipo documento *',
          alert: {
            error:
              'No es posible agregar un PTP como documento de identidad. Selecciona Cédula de identidad (DNI) o Cédula de extranjería (CE) para continuar.',
          },
        },
      },
    },
    bankData: {
      title: 'Datos bancarios',
      text: 'Completa el formulario con los datos solicitados',
      alert:
        'Recuerda ingresar tu cuenta bancaria de preferencia ya que será donde recibirás los pagos',
      inputs: {
        firstName: {
          title: 'Nombre',
          formatError: 'Tu nombre no puede contener números ni caracteres especiales',
        },
        lastName: {
          title: 'Apellido',
          formatError: 'Tu apellido no puede contener números ni caracteres especiales',
        },
        documentNumber: { title: 'Número de documento' },
        docSel: { title: 'Tipo documento' },
        bank: { title: 'Banco *', lower: 'banco' },
        accountType: {
          title: 'Tipo de cuenta *',
          lower: 'tipo de cuenta',
          cc: 'Cuenta corriente',
          ca: 'Cuenta de ahorro',
        },
        accountNumber: {
          title: 'Número de cuenta *',
          lower: 'número de cuenta',
          pattern: /^\d+$/,
          patternError: 'Ingresa solo números',
        },
      },
    },
    otherData: {
      title: 'Vehículo y dispositivo',
      text: 'Ingresa los datos solicitados *',
      alert:
        'Con esta información sabremos más de ti. Si no tienes vehículo no te preocupes, ya que podrás postular a otras tareas.',
      inputs: {
        ruc: {
          title: 'RUC *',
          helpText: '¿Dónde puedo verificar mi RUC?',
          formatError: 'Tu RUC solo puede contener dígitos',
          lower: 'RUC',
          lengthError: 'Tu RUC puede contener 11 digitos',
          pattern: /^[0-9]{11}$/,
        },
        user: {
          title: 'Usuario *',
          helpText: 'Respeta las mayúsculas y minúsculas tal cual figura en la SUNAT.',
          formatError: 'Tu usuario no puede contener caracteres especiales',
          pattern: /^[a-zA-Z0-9]{1,12}$/,
          lower: 'usuario',
        },
        solPassword: {
          title: 'Contraseña *',
          helpText: 'Respeta las mayúsculas y minúsculas tal cual figura en la SUNAT.',
          formatError: 'Tu contraseña solo puede contener 20 caracteres',
          lower: 'Contraseña',
        },
        suspension: {
          title: 'Código de exoneración 4ta categoría',
          helpText: '¿Dónde solicito este número?',
          pattern: /^[0-9]{8,20}$/,
          formatError: 'Tu código de exoneración debe tener al menos 8 dígitos',
          lower: 'código de exoneración',
          alertInfo:
            'El código tendrá vigencia hasta el 1 de enero de cada año. Luego de esa fecha, tendrás que cargar uno nuevo.',
        },
        documentNumber: { title: 'Número de documento' },
        docSel: { title: 'Tipo documento' },
        bank: { title: 'Banco *', lower: 'banco' },
        hasVehicle: {
          title: '¿Tienes vehículo? *',
          lower: 'posesión de vehículo',
          oneOption: '¿Tienes {{vehiculo}}?*',
        },
        vehicleType: { title: 'Tipo de vehículo*', lower: 'tipo de vehículo' },
        device: { title: 'Selecciona tu dispositivo*', lower: 'dispositivo' },
        recom: { title: '¿Cómo llegaste a Time Jobs? (opcional)', lower: 'recomendación' },
        patent: {
          title: 'Placa/Patente',
          lower: 'placa/patente',
          number: /^([a-zA-Z0-9_-]){6,8}$/,
          numberError: 'No usar guiones, ni espacios. Hasta 8 caracteres máximo',
        },
        needDocs: {
          text:
            'Si tu bicimoto no es 100% eléctrica o supera los 0,25 watts de potencia será necesaria una patente y sus documentos (que serán requeridos más adelante).',
          questionText: '¿Tu bicimoto requiere esta documentación?',
        },
      },
      modal: {
        title: 'Desmarcar vehículo',
        description:
          'Tu vehículo ya cuenta con documentos aprobados. Al desmarcarlo podría impactar en tus inducciones o tareas. Contáctanos a traves del chat para poder ayudarte',
      },
    },
    profilePic: {
      title: 'Foto de perfil',
      text: 'Adjunta la imagen solicitada',
      alert:
        'Algunos tips: sube una imagen en buena calidad, con buen enfoque y de frente. Evita lentes de sol y/o gorro.',
      subtitle: 'Foto de perfil',
      subtitleText:
        'La imagen debe tener un peso máximo de 10 mb y estar en el siguiente formato: jpg y png',
      error: {
        invalidDoc: 'Documento inválido.\n Adjunta nuevamente',
        genericError: 'Hubo un error.\n Adjunta nuevamente',
        overweight: 'Excede el peso máximo.\n Adjunta nuevamente',
        invalidFormat: 'Formato inválido.\n Adjunta nuevamente',
        expired: 'Documento vencido',
      },
      approvedPdf: 'Documento aprobado',
      approvedImg: 'Foto aprobada',
      revisionPdf: 'Documento en revisión',
      revisionImg: 'Foto en revisión',
      approvedOnlyUploadImg: 'Imagen cargada',
      approvedOnlyUploadPdf: 'Documento cargado',
      input: {
        title: 'Foto de perfil',
        wPdf: 'Cargar archivo',
        woPdf: 'Cargar imagen',
      },
      modal: {
        title: 'Foto de perfil',
        text:
          'Recuerda subir una imagen en buena calidad, con buen enfoque, de frente y sin accesorios (lentes de sol y/o gorro)',
      },
    },
    recordData: {
      title: 'Antecedentes',
      text: 'Adjunta el documento solicitado *',
      alerts: {
        white:
          'Si tienes tu clave única, <a href="https://www.registrocivil.cl" target="_blank" rel="no-openner">descárgalo aquí</a>',
      },
      subtitle: 'Documentos',
      subtitleText: 'Peso máximo de 10 mb. Formatos: jpg, png y pdf',
      input: {
        title: 'Certificado de Antecedentes',
        filename: 'certificado de antecedentes',
        wPdf: 'Cargar documento',
        woPdf: 'Cargar imagen',
        errors: {
          invalidFile: 'Ingresa un archivo válido',
          requiredFile: 'El archivo es requerido',
        },
      },
      modal: {
        text: 'Adjunta el documento que indicamos a continuación:',
      },
    },
    frameworkAgreement: {
      title: 'Acuerdo Marco',
      text: 'Sigue nuestras indicaciones',
      subtitle: 'La paciencia es la mejor de las virtudes :)',
      subtitleText:
        'Nuestro staff se contactará contigo para que puedas firmar el Acuerdo Marco. Mantente atento a tu correo :)',
      image: '/center/framework-agreement-image2.png',
    },
    otherDocs: {
      title: 'Documentos',
      text: 'Adjunta los documentos solicitados',
      alert: 'Asegúrate que el documento esté vigente y de enviar una imagen de buena calidad.',
      info:
        'Una vez que nuestro equipo revise y valide estos documentos, su estado cambiará de “En revisión” a “Completado”.',
      subtitle: 'Identificación',
      subtitleText: 'Selecciona un documento con el que puedas comprobar tu identidad',

      inputs: {
        ruc_certificate: {
          title: 'Certificado RUC',
          modal: {
            text: 'Adjunta el documento que indicamos a continuación.',
          },
        },
        id_card_front: {
          title: 'Cédula de identidad frontal',
          modal: {
            text:
              'Sube una imagen de la parte frontal de tu cédula, intentando que los datos se vean con nitidez.',
          },
          dateLabel: 'Vencimiento cédula',
        },
        id_card_reverse: {
          title: 'Cédula de identidad reverso',
          modal: {
            text:
              'Sube una imagen de la parte trasera de tu cédula, intentando que los datos se vean con nitidez.',
          },
          dateLabel: 'Vencimiento cédula',
        },
        ppt_certificate: {
          title: 'Certificado de PPT',
          modal: {
            text: 'Adjunta el documento que indicamos a continuación.',
          },
        },
        suspension_certificate: {
          title: 'Código de exoneración de retención de 4ta categoría',
          dateLabel: 'Vencimiento documento',
          modal: {
            text:
              "Puedes gestionar este documento en 'Mis trámites y consultas' con tu RUC y clave SOL, debes seguir la ruta 'Personas' / 'Otras Declaraciones y solicitudes' / 'Solicito Suspensión de Retenciones de 4ta Categoría' / 'Suspensión de Retención de 4ta Categoría' / 'Formulario 1609'. Luego, completa la información solicitada por el sistema y obtendrás dentro del informe el número de operación.",
          },
        },
        pension_certificate: {
          dateLabel: 'Emisión certificado',
        },
        eps_certificate: {
          dateLabel: 'Emisión certificado',
        },
        kind: {
          label: 'Identificación *',
          required: 'El tipo de documento es requerido.',
          options: {
            id: 'Cédula de Identidad',
            visa_application: 'Trámite de Visa Definitiva',
            PTP: 'PTP (Permiso temporal de permanencia)',
            PEP: 'PEP (Permiso especial de permanencia)',
            foreign_ID: 'Cédula Extranjería',
          },
          titles: {
            id: { front: 'Cédula de identidad frontal', reverse: 'Cédula de identidad reverso' },
            PTP: { front: 'PTP frontal', reverse: 'PTP reverso' },
            foreign_ID: { front: 'Cédula frontal', reverse: 'Cédula reverso' },
            PEP: { front: 'Permiso (pág. 1)', reverse: 'Permiso (pág. 2)' },
            visa_application: {
              front: 'Comprobante de trámite (pág. 1)',
              reverse: 'Comprobante de trámite (pág. 2)',
            },
            info: {
              visa_application: 'Si tu comprobante tiene solo 1 página, carga 2 veces la misma.',
              PEP: 'Si tu permiso tiene solo 1 página, carga 2 veces la misma.',
            },
          },
          itsNotID: 'Sube una imagen del documento, intentando que los datos se vean con nitidez.',
        },
      },
    },
    additionalInfo: {
      title: 'Jobber Pack y documentos vehículo',
      text: 'Ingresa los datos solicitados ',
      description:
        'Estos corresponden a indumentaria necesaria para la realización de ciertas tareas. Por ejemplo, algunas tareas de operario de bodega requieren tener Zapatos de seguridad, por lo que para poder postular deberás marcar que los posees.',
      requestButtonTitle: '¿Necesitas algún elemento del jobber pack?',
      requestEppsButton: 'Solicitar Elementos',
      alert:
        'Para cierto tipo de tareas se requerirá información adicional. Puedes cargarla desde ya o cuando sea requerida.',
      inputs: {
        ppe: {
          title: 'Elementos del Jobber pack (opcional)',
        },
      },
      vehiclesDocs: {
        title: 'Documentos de vehículos',
        subtitleText:
          'Los archivos deben tener un peso máximo de 10 MB y deben estar en los siguientes formatos: jpg, png o pdf.',
        infoAlert: {
          head:
            'Recuerda que las inducciones que vayas realizando requerirán distintos documentos los cuales deberás cargar para poder finalizarlas.',
          auto:
            'Si cuentas con un seguro adicional <b> que no sea el seguro obligatorio (SOAP)</b>, puedes cargarlo acá.',
          bicimoto:
            'Si tu bicimoto no es 100% eléctrica o supera los 0,25 watts de potencia, debes cargar el <b> permiso de circulación bicimoto y licencia de conducir clase C.</b>',
        },
        subsections: {
          auto: 'Documentos auto',
          moto: 'Documentos moto',
          bicimoto: 'Documentos bicimoto',
          van: 'Documentos van',
          furgon: 'Documentos furgón',
          none: 'Sin información',
          undefined: 'Sin información',
        },
      },
      extraDocs: {
        title: 'Certificados (opcional)',
        subtitle: 'Peso máximo de 10 MB. Formatos: jpg, png o pdf.',
      },
      epp: {
        modal: {
          textReferenceImg: 'Imagen referencial',
        },
      },
    },
    steps: {
      'personal-data': 'Datos personales',
      'bank-data': 'Datos bancarios',
      'other-data': 'Vehículo y dispositivo',
      'records-data': 'Antecedentes',
      'other-docs': 'Documentos',
      'additional-info': 'Jobber Pack y documentos vehículo',
      modal: {
        basic: {
          title: 'No pierdas los datos al salir',
          description: 'Guarda los datos ingresados, para no tener que completarlos nuevamente.',
          discardText: 'Seguir sin guardar',
          submitText: 'Guardar y salir',
        },
        middle: {
          title: 'No salgas sin guardar',
          description: 'Tienes uno o más datos por completar para poder guardar este paso.',
          discardText: 'Continuar editando',
          submitText: 'Salir sin guardar',
        },
        full: {
          title: 'Guarda antes de continuar',
          description: 'Antes de pasar al siguiente paso, recuerda guardar tu información.',
          discardText: 'Seguir sin guardar',
          submitText: 'Guardar y continuar',
        },
      },
      status: {
        completed: 'Completado',
        in_process: 'En revisión',
        pending: 'Pendiente',
        blocked: 'Pendiente',
        in_review: 'En revisión',
        objected: 'Documento(s) inválido(s)',
      },
      alerts: {
        'personal-data': '<b>Datos personales</b> guardados con éxito.',
        'bank-data': '<b>Datos bancarios</b> guardados con éxito.',
        'other-data': '<b>Vehículo y dispositivo</b> guardados con éxito.',
        'records-data':
          'Datos actualizados con éxito. Espera la revisión y aprobación de nuestro equipo.',
        'other-docs': '<b>Documentos</b> guardados con éxito.',
      },
    },
    uploadDocument: {
      messages: {
        approved: 'Documento aprobado',
        in_review: 'Documento en revisión',
        objected: 'Documento inválido',
        generic_error: 'Hubo un error.\n Adjunta nuevamente',
        overweight: 'Excede el peso máximo.\n Adjunta nuevamente',
        invalid_format: 'Formato inválido.\n Adjunta nuevamente',
        pending: 'Pendiente',
        expired: 'Documento vencido',
        rejected: 'Documento rechazado',
        pending_expiration: '',
        temp: '',
      },
      buttons: {
        update: 'Actualizar documento',
        upload: 'Cargar documento',
        edit: 'Editar',
        submit: 'Guardar',
        cancel: 'Cancelar',
      },
      alerts: {
        pending: 'Espera la revisión y aprobación del documento por parte de nuestro equipo.',
        after:
          'El documento anterior, seguirá vigente hasta que el nuevo sea revisado y aprobado por nuestro equipo.',
        pending_expiration:
          'Este documento está por vencer ({{date}}).\nActualízalo y espera la aprobación de nuestro equipo seguir tomando tareas.',
        expired: 'Este documento venció el {{date}}. Carga uno vigente para seguir tomando tareas.',
        inputExpired:
          'Este documento ya venció. Carga un documento vigente o en caso de no tenerlo, comienza a gestionar su renovación.',
        inputToExpire:
          'Este documento está por vencer. Te recomendamos comenzar a gestionar su renovación.',
        objected:
          'El documento ha sido objetado por <b>"{{reason}}"</b>. Carga uno correcto para continuar.',
      },
      editing: {
        title: 'Actualización de documento',
        subtitle: 'Ingresa un nuevo documento',
        simple: 'Edita la información necesaria',
        helper: 'Doc. vigente',
      },
      dateLabels: {
        id_card_front: 'Vencimiento cédula',
        id_card_reverse: 'Vencimiento cédula',
        pension_certificate: 'Vencimiento del documento',
        eps_certificate: 'Vencimiento del documento',
        suspension_certificate: 'Emisión del documento',
        carInsurance: 'Vencimiento permiso',
        carDriverLicense: 'Vencimiento licencia',
        carRegistrationCertificate: 'Vencimiento permiso',
        vaccinationCard: 'Emisión certificado',
        healthCardWithFoodHandling: 'Vencimiento del documento',
        carRoadWorthinessTest: 'Vencimiento del documento',
        carSoat: 'Vencimiento del documento',
        carOwnershipCard: 'Vencimiento del documento',
        motorcycleRegistrationCertificate: 'Vencimiento del documento',
        motorcycleDriverLicense: 'Vencimiento del documento',
        furgonRegistrationCertificate: 'Vencimiento del documento',
        furgonDriverLicense: 'Vencimiento del documento',
        motorcycleSoat: 'Vencimiento del documento',
        motorcycleOwnershipCard: 'Vencimiento del documento',
        vanRoadWorthinessTest: 'Vencimiento del documento',
        vanDriverLicense: 'Vencimiento del documento',
        vanOwnershipCard: 'Vencimiento del documento',
        vanSoat: 'Vencimiento del documento',
        id: 'Vencimiento del documento',
        visa_application: 'Emisión comprobante',
        PTP: 'Vencimiento del documento',
        PEP: 'Emisión del documento',
        foreign_ID: 'Vencimiento del documento',
        foodHandlingCertificate: 'Vencimiento del documento',
        epsCertificate: 'Vencimiento del documento',
        pensionCertificate: 'Vencimiento del documento',
        mopedRegistrationCertificate: 'Vencimiento del documento',
        mopedDriverLicense: 'Vencimiento del documento',
      },
      fileNames: {
        records: 'certificado antecedentes',
        id_card_front: 'cédula identidad frontal',
        id_card_reverse: 'cédula identidad reverso',
        carInsurance: 'seguro adicional auto',
        carDriverLicense: 'licencia conducir',
        carRegistrationCertificate: 'permiso circulación',
        vaccinationCard: 'carnet vacunación',
        pension_certificate: 'certificado pensión',
        eps_certificate: 'certificado EPS',
        suspension_certificate: 'código exoneración',
        healthCardWithFoodHandling: 'carnet sanidad',
        carOwnershipCard: 'tarjeta propiedad',
        carSoat: 'soat',
        carRoadWorthinessTest: 'revisión técnica',
        furgonRegistrationCertificate: 'seguro adiconal furgon',
        furgonDriverLicense: 'licencia conducir',
        motorcycleRegistrationCertificate: 'permiso circulación',
        motorcycleDriverLicense: 'licencia conducir',
        id_front: 'cédula identidad frontal',
        id_reverse: 'cédula identidad reverso',
        visa_application_front: 'tramite visa frontal',
        visa_application_reverse: 'tramite visa reverso',
        PTP_front: 'PTP frontal',
        PTP_reverse: 'PTP reverso',
        PEP_front: 'PEP frontal',
        PEP_reverse: 'PEP reverso',
        foreign_ID_front: 'cédula extranjería frontal',
        foreign_ID_reverse: 'cédula extranjería reverso',
        foodHandlingCertificate: 'manipulación alimentos',
        epsCertificate: 'certificado eps',
        pensionCertificate: 'certificado pensión',
        mopedRegistrationCertificate: 'permiso circulación',
        mopedDriverLicense: 'licencia conducir',
        motorcycleSoat: 'soat',
        motorcycleOwnershipCard: 'tarjeta propiedad',
      },
      keepLabels: {
        records: 'Seguir utilizando documento actual',
        id_card_front: 'Seguir utilizando cédula actual',
        id_card_reverse: 'Seguir utilizando cédula actual',
        pension_certificate: 'Seguir utilizando documento actual',
        eps_certificate: 'Seguir utilizando documento actual',
        suspension_certificate: 'Seguir utilizando documento actual',
        carInsurance: 'Seguir utilizando documento actual',
        carDriverLicense: 'Seguir utilizando documento actual',
        carRegistrationCertificate: 'Seguir utilizando documento actual',
        vaccinationCard: 'Seguir utilizando documento actual',
        healthCardWithFoodHandling: 'Seguir utilizando documento actual',
        carRoadWorthinessTest: 'Seguir utilizando documento actual',
        carSoat: 'Seguir utilizando documento actual',
        carOwnershipCard: 'Seguir utilizando documento actual',
        motorcycleRegistrationCertificate: 'Seguir utilizando documento actual',
        motorcycleDriverLicense: 'Seguir utilizando documento actual',
        furgonRegistrationCertificate: 'Seguir utilizando documento actual',
        furgonDriverLicense: 'Seguir utilizando documento actual',
        motorcycleSoat: 'Seguir utilizando documento actual',
        motorcycleOwnershipCard: 'Seguir utilizando documento actual',
        vanRoadWorthinessTest: 'Seguir utilizando documento actual',
        vanDriverLicense: 'Seguir utilizando documento actual',
        vanOwnershipCard: 'Seguir utilizando documento actual',
        vanSoat: 'Seguir utilizando documento actual',
        id: 'Seguir utilizando cédula actual',
        visa_application: 'Seguir utilizando documento actual',
        PTP: 'Seguir utilizando documento actual',
        PEP: 'Seguir utilizando documento actual',
        foreign_ID: 'Seguir utilizando documento actual',
        foodHandlingCertificate: 'Seguir utilizando documento actual',
        epsCertificate: 'Seguir utilizando documento actual',
        pensionCertificate: 'Seguir utilizando documento actual',
        mopedRegistrationCertificate: 'Seguir utilizando documento actual',
        mopedDriverLicense: 'Seguir utilizando documento actual',
      },
      inputs: {
        date: {
          label: 'Fecha',
          required: 'La fecha es requerida',
        },
        file: {
          required: 'El archivo es requerido',
        },
      },
      withoutDate: 'Cédula sin fecha de vencimiento',
    },
  },
};
