import center from './modules/center';

export default {
  ...center,
  global: {
    supportEmail: 'ayuda@timejobs.work',
    firstLevel: 'Estado',
    secondLevel: 'Municipio',
    secondLevel_plural: 'Municipios',
    firstLevel_gender: 'male',
    secondLevel_gender: 'male',
  },
};
