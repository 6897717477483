export default {
  center: {
    main: {
      highDemand:
        'Actualmente tu zona se encuentra con baja demanda de tareas, por lo que podría causar un retraso en la revisión de tu información. Te avisaremos a través de email cuando esté todo listo.\n\nTermina de completar tus datos y podrás acceder a turnos en otras $t(global.secondLevel_plural, lowercase)/zonas. ¿Tienes dudas? Puedes hacer tus consultas en “página help center” o escríbenos a $t(global.supportEmail)',
      carousel: {
        2: 'Podras postular a una tarea, una vez te inscribas como Jobber. Mantente atento, ya que las inducciones son por tiempo limitado.',
      },
      allInfoFilled:
        'Recibimos tu información correctamente. Coordinaremos la firma de tu acuerdo para continuar con tu registro.',
      advice: {
        texts: {
          jobber: {
            2: ' Realizar y completar las inducciones aumenta la probabilidad de que seas asignado a las tareas postuladas.',
          },
        },
      },
    },
    personalData: {
      alert:
        'Para seguir avanzando en el registro, debes llenar todos los datos requeridos.',
      inputs: {
        docSel: {
          cc: 'Cédula de Ciudadanía',
          ceco: 'Cédula de Extranjería',
          passport: 'Pasaporte',
          pep: 'PEP',
          ppt: 'PPT',
        },
        documentNumber: {
          alreadyTaken:
            'Este número de documento ya se encuentra registrado, para más información comunícate con contacto.co@timejobs.work',
          pattern: {
            cc: /^(\d){6,12}$/,
            ceco: /^([a-zA-Z]|\d)+$/,
            passport: /^(\d){10}$/,
            pep: /^([a-zA-Z]|\d)+$/,
            ppt: /^(\d){4,10}$/,
          },
          message: {
            cc: 'Ingresa entre 6 y 12 dígitos',
            ceco: 'Ingresa números o letras',
            passport: 'Ingresa 10 dígitos',
            pep: 'Ingresa números o letras',
            ppt: 'Ingresa entre 4 y 10 dígitos',
          },
        },
      },
    },
    frameworkAgreement: {
      subtitleText:
        'Te enviaremos este documento a tu correo a través de la página de Viafirma. Una vez firmado digitalmente, nuestro equipo lo revisará. Mantente atento a tu correo :)',
    },
    otherDocs: {
      inputs: {
        pension_certificate: {
          title: 'Certificado de pensión',
          modal: {
            text: 'Este documento debe tener una vigencia de 30 días',
          },
        },
        eps_certificate: {
          title: 'Certificado de EPS',
          modal: {
            text: 'Este documento debe tener una vigencia de 30 días',
          },
        },
      },
    },
  },
};
