import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// * Translations
import EsCLTranslations from './translations/es-cl';
import EsCOTranslations from './translations/es-co';
import EsMXTranslations from './translations/es-mx';
import EsPETranslations from './translations/es-pe';
import EsTranslations from './translations/es';

// translation catalog
const resources = {
  es: {
    translation: {
      ...EsTranslations,
    },
  },
  'es-CL': {
    translation: {
      ...EsCLTranslations,
    },
  },
  'es-CO': {
    translation: {
      ...EsCOTranslations,
    },
  },
  'es-MX': {
    translation: {
      ...EsMXTranslations,
    },
  },
  'es-PE': {
    translation: {
      ...EsPETranslations,
    },
  },
};

// initialize i18next with catalog and language to use
i18n.use(LanguageDetector).init({
  resources,
  detection: {
    order: ['localStorage'],
    lookupLocalStorage: 'i18nextLng',
    caches: ['localStorage'],
  },
  fallbackLng: 'es',
  interpolation: {
    format: (value, format) => {
      if (format === 'uppercase') return value.toUpperCase();
      if (format === 'lowercase') return value.toLowerCase();
      return value;
    },
  },
});

export default i18n;
