export default {
  center: {
    personalData: {
      inputs: {
        zipCode: {
          title: 'Código postal *',
          lower: 'código postal',
          pattern: /^(\d{5})$/,
          patternError: 'El código debe ser de 5 números',
        },
        documentNumber: {
          title: 'CURP *',
          lower: 'curp',
          pattern: /^([a-zA-Z]|\d){18}$/,
          message: 'Debes ingresar 18 números o letras',
          alreadyTaken:
            'Este CURP ya se encuentra registrado, para más información comunícate con ayuda@timejobs.work',
        },
      },
    },
    bankData: {
      inputs: {
        documentNumber: { title: 'CURP' },
        accountNumber: { pattern: /^\d{10}$/, patternError: 'Ingresa 10 dígitos' },
        interbankCode: {
          title: 'Cuenta clabe *',
          lower: 'cuenta clabe',
          pattern: /^\d{18}$/,
          patternError: 'Ingresa los 18 dígitos de tu cuenta clabe',
        },
      },
    },
    recordData: {
      alert: [
        'Existen múltiples fuentes en donde puedes obtener tu certificado y este puede variar según el departamento en que te encuentres. Obtén el certificado de antecedentes que corresponda y cargarlo en esta sección.',
        ' ',
        ' ',
      ],
    },
  },
};
