export default {
  center: {
    main: {
      highDemand:
        'Actualmente tu zona se encuentra con baja demanda de tareas, por lo que podría causar un retraso en la revisión de tu información. Te avisaremos a través de email cuando esté todo listo.\n\nTermina de completar tus datos y podrás acceder a turnos en otras $t(global.secondLevel_plural, lowercase)/zonas. ¿Tienes dudas? Puedes hacer tus consultas en “página help center” o escríbenos a $t(global.supportEmail)',
      carousel: {
        1: 'Los pagos se realizarán la semana siguiente a la trabajada (día jueves).',
        calendarImage: '/calendar.svg',
      },
    },
    personalData: {
      inputs: {
        documentNumber: {
          title: 'RUT *',
          lower: 'rut',
          pattern: /^([1-9][0-9]?)?.?([0-9]{1,3})?.?([0-9]{1,3})-([0-9|k|K]{1})$/,
          message: 'Ingresa un rut válido',
          alreadyTaken:
            'Este RUT ya se encuentra registrado, para más información comunícate con $t(global.supportEmail)',
        },
        phoneNumber: {
          pattern: /^(\d){9}$/,
          message: 'Ingresa un teléfono de 9 dígitos',
        },
      },
    },
    bankData: {
      inputs: {
        accountType: { cv: 'Cuenta vista / rut' },
      },
    },
  },
};
