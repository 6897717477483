import center from './modules/center';

export default {
  ...center,
  global: {
    currencySymbol: 'S/',
    supportEmail: 'soporte.pe@timejobs.work',
    firstLevel: 'Provincia',
    secondLevel: 'Distrito',
    secondLevel_plural: 'Distritos',
    firstLevel_gender: 'female',
    secondLevel_gender: 'male',
  },
};
