export default {
  center: {
    main: {
      carousel: {
        2: 'Podrás postular a una tarea, una vez te suscribas como Jobber. Mantente atento, ya que las inducciones son por tiempo limitado.',
      },
      remainingSteps: {
        links: {
          indications: 'Ver información',
        },
        messages: {
          noSign: '¡Estás muy cerca de convertirte en Jobber!',
        },
        steps: {
          frameworkAgreement: 'Suscripción',
          otherInfo: 'Vehículo, dispositivo y Sunat',
        },
      },
    },
    personalData: {
      inputs: {
        docSel: { dni: 'Cédula de identidad (DNI)', cepe: 'Cédula de extranjería (CE)' },
        documentNumber: {
          alreadyTaken:
            'Este número de documento ya se encuentra registrado, para más información comunícate con soporte.pe@timejobs.work o a través de nuestro chat.',
          pattern: {
            dni: /^(\d){8}$/,
            cepe: /^(\d){0,12}$/,
            ptp: /^(\d){0,12}$/,
            cpp: /^(\d){0,12}$/,
          },
          message: {
            dni: 'Ingresa 8 dígitos',
            cepe: 'Ingresa hasta 12 dígitos',
            ptp: 'Ingresa hasta 12 dígitos',
            cpp: 'Ingresa hasta 12 dígitos',
          },
        },
        phoneNumber: {
          pattern: /^(\d){9}$/,
          message: 'Ingresa un teléfono de 9 dígitos',
        },
      },
    },
    bankData: {
      inputs: {
        accountType: {
          ca: 'Cuenta de ahorros',
        },
        accountNumber: { pattern: /^\d{0,18}$/, patternError: 'Ingresa hasta 18 dígitos' },
        interbankCode: {
          title: 'CCI *',
          lower: 'cci',
          pattern: /^\d{20}$/,
          patternPeru: /^\d{17}$/,
          patternError: 'Ingresa los 20 dígitos de tu cci',
        },
      },
    },
    frameworkAgreement: {
      title: 'Suscripción',
      text: 'Sigue nuestras indicaciones.',
      subtitle: '¡Estás muy cerca de convertirte en Jobber!',
      subtitleText:
        'Hemos recibido tu registro, nuestro equipo de Onboarding te contactará para invitarte a nuestras oficinas, activar tu perfil y entregar tu Jobber Pack una vez tengamos cupos disponibles en tu zona.',
      image: '/center/framework-agreement-image3.png',
    },
    otherData: {
      title: 'Vehículo, dispositivo y Sunat',
      inputs: {
        peruCredentials: 'Ingresa los siguientes datos*',
        modal: {
          title: 'Verificación RUC',
          subtitleInit: 'Puedes verificar tu RUC en la ',
          subtitleMiddle: ' en la sección ',
          subtitleEnd: 'Consultar el estado del RUC',
          redirect: 'https://www.sunat.gob.pe',
          url: 'www.sunat.gob.pe',
          alt: 'imagen de la sunat',
          imgSrc: '/center/tooltips/PE/sunat.png',
          imgSrcMobile: '/center/tooltips/PE/sunat_mobile.png',
        },
        modalSuspension: {
          title: 'Solicitud número de operación',
          subtitleInit: 'Al acceder a ',
          redirect: 'https://ww3.sunat.gob.pe/sol.html',
          url: 'https://ww3.sunat.gob.pe/sol.html',
          alt: 'imagen de la sunat',
          imgSrc: '/center/tooltips/PE/suspension_certificate.png',
          imgSrcMobile: '/center/tooltips/PE/suspension_mobile.png',
          subtitleMiddle: ` en 'Mis trámites y consultas' con tu RUC y clave SOL, debes seguir la ruta 'Personas' / 'Otras Declaraciones y solicitudes' / 'Solicito Suspensión de Retenciones de 4ta Categoría' / 'Suspensión de Retención de 4ta Categoría' / 'Formulario 1609'. Luego, completa la información solicitada por el sistema y obtendrás dentro del informe el número de operación.`,
        },
        user: {
          title: 'Usuario SUNAT *',
        },
      },
    },
    otherDocs: {
      title: 'Documentos',
      text: 'Adjunta los documentos solicitados',
      alert: 'Asegúrate que el documento esté vigente y de enviar una imagen de buena calidad.',
      info:
        'Una vez que nuestro equipo revise y valide estos documentos, su estado cambiará de “En revisión” a “Completado”.',
      subtitle: 'Identificación',
      subtitleText: 'Selecciona un documento con el que puedas comprobar tu identidad',

      inputs: {
        ruc_certificate: {
          title: 'Certificado RUC',
          modal: {
            text: 'Adjunta el documento que indicamos a continuación.',
          },
        },
        id_card_front: {
          title: 'DNI frontal',
          modal: {
            text:
              'Sube una imagen de la parte frontal de tu DNI, intentando que los datos se vean con nitidez.',
          },
          dateLabel: 'Vencimiento DNI',
        },
        id_card_reverse: {
          title: 'DNI reverso',
          modal: {
            text:
              'Sube una imagen de la parte trasera de tu DNI, intentando que los datos se vean con nitidez.',
          },
          dateLabel: 'Vencimiento DNI',
        },
        ppt_certificate: {
          title: 'Certificado de PPT',
          modal: {
            text: 'Adjunta el documento que indicamos a continuación.',
          },
        },
        suspension_certificate: {
          title: 'Cód. de exoneración 4ta categoría',
          dateLabel: 'Vencimiento documento',
          modal: {
            text:
              "Puedes gestionar este documento en 'Mis trámites y consultas' con tu RUC y clave SOL, debes seguir la ruta 'Personas' / 'Otras Declaraciones y solicitudes' / 'Solicito Suspensión de Retenciones de 4ta Categoría' / 'Suspensión de Retención de 4ta Categoría' / 'Formulario 1609'. Luego, completa la información solicitada por el sistema y obtendrás dentro del informe el número de operación.",
          },
          alert:
            'Solo al ingresar el código de exoneración en el paso <strong>“Vehículo, dispositivo y Sunat”,</strong> podrás cargar el documento de emisión.',
        },
        pension_certificate: {
          dateLabel: 'Emisión certificado',
        },
        eps_certificate: {
          dateLabel: 'Emisión certificado',
        },
        kind: {
          label: 'Identificación *',
          required: 'El tipo de documento es requerido.',
          options: {
            id: 'Cédula de identidad (DNI)',
            visa_application: 'Trámite de Visa Definitiva',
            PTP: 'PTP (Permiso temporal de permanencia)',
            PEP: 'PEP (Permiso especial de permanencia)',
            foreign_ID: 'Cédula de extranjería (CE)',
          },
          titles: {
            id: { front: 'DNI frontal', reverse: 'DNI reverso' },
            PTP: { front: 'PTP frontal', reverse: 'PTP reverso' },
            foreign_ID: {
              front: 'Carnet de Extranjería frontal',
              reverse: 'Carnet de Extranjería reverso',
            },
            PEP: { front: 'Permiso (pág. 1)', reverse: 'Permiso (pág. 2)' },
            visa_application: {
              front: 'Comprobante de trámite (pág. 1)',
              reverse: 'Comprobante de trámite (pág. 2)',
            },
            info: {
              visa_application: 'Si tu comprobante tiene solo 1 página, carga 2 veces la misma.',
              PEP: 'Si tu permiso tiene solo 1 página, carga 2 veces la misma.',
            },
          },
          itsNotID: 'Sube una imagen del documento, intentando que los datos se vean con nitidez.',
        },
      },
    },
    uploadDocument: {
      messages: {
        approved: 'Documento aprobado',
        in_review: 'Documento en revisión',
        objected: 'Documento inválido',
        generic_error: 'Hubo un error.\n Adjunta nuevamente',
        overweight: 'Excede el peso máximo.\n Adjunta nuevamente',
        invalid_format: 'Formato inválido.\n Adjunta nuevamente',
        pending: 'Pendiente',
        expired: 'Documento vencido',
        rejected: 'Documento rechazado',
        pending_expiration: '',
        temp: '',
      },
      buttons: {
        update: 'Actualizar documento',
        upload: 'Cargar documento',
        edit: 'Editar',
        submit: 'Guardar',
        cancel: 'Cancelar',
      },
      alerts: {
        pending: 'Espera la revisión y aprobación del documento por parte de nuestro equipo.',
        after:
          'El documento anterior, seguirá vigente hasta que el nuevo sea revisado y aprobado por nuestro equipo.',
        pending_expiration:
          'Este documento está por vencer ({{date}}).\nActualízalo y espera la aprobación de nuestro equipo seguir tomando tareas.',
        expired: 'Este documento venció el {{date}}. Carga uno vigente para seguir tomando tareas.',
        inputExpired:
          'Este documento ya venció. Carga un documento vigente o en caso de no tenerlo, comienza a gestionar su renovación.',
        inputToExpire:
          'Este documento está por vencer. Te recomendamos comenzar a gestionar su renovación.',
        objected:
          'El documento ha sido objetado por <b>"{{reason}}"</b>. Carga uno correcto para continuar.',
      },
      editing: {
        title: 'Actualización de documento',
        subtitle: 'Ingresa un nuevo documento',
        simple: 'Edita la información necesaria',
        helper: 'Doc. vigente',
      },
      dateLabels: {
        id_card_front: 'Vencimiento DNI',
        id_card_reverse: 'Vencimiento DNI',
        pension_certificate: 'Vencimiento del documento',
        eps_certificate: 'Vencimiento del documento',
        suspension_certificate: 'Emisión del documento',
        carInsurance: 'Vencimiento permiso',
        carDriverLicense: 'Vencimiento licencia',
        carRegistrationCertificate: 'Vencimiento permiso',
        vaccinationCard: 'Emisión certificado',
        healthCardWithFoodHandling: 'Vencimiento del documento',
        carRoadWorthinessTest: 'Vencimiento del documento',
        carSoat: 'Vencimiento del documento',
        carOwnershipCard: 'Vencimiento del documento',
        motorcycleRegistrationCertificate: 'Vencimiento del documento',
        motorcycleDriverLicense: 'Vencimiento del documento',
        furgonRegistrationCertificate: 'Vencimiento del documento',
        furgonDriverLicense: 'Vencimiento del documento',
        motorcycleSoat: 'Vencimiento del documento',
        motorcycleOwnershipCard: 'Vencimiento del documento',
        vanRoadWorthinessTest: 'Vencimiento del documento',
        vanDriverLicense: 'Vencimiento del documento',
        vanOwnershipCard: 'Vencimiento del documento',
        vanSoat: 'Vencimiento del documento',
        id: 'Vencimiento del documento',
        visa_application: 'Emisión comprobante',
        PTP: 'Vencimiento del documento',
        PEP: 'Emisión del documento',
        foreign_ID: 'Vencimiento del documento',
        foodHandlingCertificate: 'Vencimiento del documento',
        epsCertificate: 'Vencimiento del documento',
        pensionCertificate: 'Vencimiento del documento',
        mopedRegistrationCertificate: 'Vencimiento del documento',
        mopedDriverLicense: 'Vencimiento del documento',
      },
      fileNames: {
        records: 'certificado antecedentes',
        id_card_front: 'DNI frontal',
        id_card_reverse: 'DNI reverso',
        carInsurance: 'seguro adicional auto',
        carDriverLicense: 'licencia conducir',
        carRegistrationCertificate: 'permiso circulación',
        vaccinationCard: 'carnet vacunación',
        pension_certificate: 'certificado pensión',
        eps_certificate: 'certificado EPS',
        suspension_certificate: 'código exoneración',
        healthCardWithFoodHandling: 'carnet sanidad',
        carOwnershipCard: 'tarjeta propiedad',
        carSoat: 'soat',
        carRoadWorthinessTest: 'revisión técnica',
        furgonRegistrationCertificate: 'seguro adiconal furgon',
        furgonDriverLicense: 'licencia conducir',
        motorcycleRegistrationCertificate: 'permiso circulación',
        motorcycleDriverLicense: 'licencia conducir',
        id_front: 'DNI frontal',
        id_reverse: 'DNI reverso',
        visa_application_front: 'tramite visa frontal',
        visa_application_reverse: 'tramite visa reverso',
        PTP_front: 'PTP frontal',
        PTP_reverse: 'PTP reverso',
        PEP_front: 'PEP frontal',
        PEP_reverse: 'PEP reverso',
        foreign_ID_front: 'Carnet de Extranjería frontal',
        foreign_ID_reverse: 'Carnet de Extranjería reverso',
        foodHandlingCertificate: 'manipulación alimentos',
        epsCertificate: 'certificado eps',
        pensionCertificate: 'certificado pensión',
        mopedRegistrationCertificate: 'permiso circulación',
        mopedDriverLicense: 'licencia conducir',
        motorcycleSoat: 'soat',
        motorcycleOwnershipCard: 'tarjeta propiedad',
      },
      keepLabels: {
        records: 'Seguir utilizando documento actual',
        id_card_front: 'Seguir utilizando DNI actual',
        id_card_reverse: 'Seguir utilizando DNI actual',
        pension_certificate: 'Seguir utilizando documento actual',
        eps_certificate: 'Seguir utilizando documento actual',
        suspension_certificate: 'Seguir utilizando documento actual',
        carInsurance: 'Seguir utilizando documento actual',
        carDriverLicense: 'Seguir utilizando documento actual',
        carRegistrationCertificate: 'Seguir utilizando documento actual',
        vaccinationCard: 'Seguir utilizando documento actual',
        healthCardWithFoodHandling: 'Seguir utilizando documento actual',
        carRoadWorthinessTest: 'Seguir utilizando documento actual',
        carSoat: 'Seguir utilizando documento actual',
        carOwnershipCard: 'Seguir utilizando documento actual',
        motorcycleRegistrationCertificate: 'Seguir utilizando documento actual',
        motorcycleDriverLicense: 'Seguir utilizando documento actual',
        furgonRegistrationCertificate: 'Seguir utilizando documento actual',
        furgonDriverLicense: 'Seguir utilizando documento actual',
        motorcycleSoat: 'Seguir utilizando documento actual',
        motorcycleOwnershipCard: 'Seguir utilizando documento actual',
        vanRoadWorthinessTest: 'Seguir utilizando documento actual',
        vanDriverLicense: 'Seguir utilizando documento actual',
        vanOwnershipCard: 'Seguir utilizando documento actual',
        vanSoat: 'Seguir utilizando documento actual',
        id: 'Seguir utilizando documento actual',
        visa_application: 'Seguir utilizando documento actual',
        PTP: 'Seguir utilizando documento actual',
        PEP: 'Seguir utilizando documento actual',
        foreign_ID: 'Seguir utilizando documento actual',
        foodHandlingCertificate: 'Seguir utilizando documento actual',
        epsCertificate: 'Seguir utilizando documento actual',
        pensionCertificate: 'Seguir utilizando documento actual',
        mopedRegistrationCertificate: 'Seguir utilizando documento actual',
        mopedDriverLicense: 'Seguir utilizando documento actual',
      },
      inputs: {
        date: {
          label: 'Fecha',
          required: 'La fecha es requerida',
        },
        file: {
          required: 'El archivo es requerido',
        },
      },
      withoutDate: 'Documento sin fecha de vencimiento',
    },
    steps: {
      'other-data': 'Vehículo, dispositivo y Sunat',
      alerts: {
        'other-data': '<b>Vehículo, dispositivo y Sunat</b> guardados con éxito.',
      },
    },
  },
};
