export default {
  location: {
    levels: [
      {
        firstLevel: 'Región',
        secondLevel: 'Provincia',
        thirdLevel: 'Comuna',
      },
      {
        firstLevel: 'Estado',
        secondLevel: 'Municipio',
        thirdLevel: 'Localidad',
      },
      {
        firstLevel: 'Departamento',
        secondLevel: 'Provincia',
        thirdLevel: 'Distrito',
      },
      {
        firstLevel: 'Departamento',
        secondLevel: 'Municipio',
        thirdLevel: 'Localidad',
      },
      {
        firstLevel: 'Provincia',
        secondLevel: 'Ciudad',
        thirdLevel: 'Cantón',
      },
    ],
  },
};
