import auth from './modules/auth';
import center from './modules/center';

export default {
  ...center,
  ...auth,
  global: {
    supportEmail: 'contacto.co@timejobs.work',
    firstLevel: 'Departamento',
    secondLevel: 'Localidad',
    secondLevel_plural: 'Localidades',
    firstLevel_gender: 'male',
    secondLevel_gender: 'female',
  },
};
