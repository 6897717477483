import auth from './modules/auth';
import center from './modules/center';
import location from './modules/location';

export default {
  ...auth,
  ...center,
  ...location,
  global: {
    currencySymbol: '$',
    add: 'Agregar',
    delete: 'Eliminar',
    edit: 'Editar',
    save: 'Guardar',
    save_continue: 'Guardar y continuar',
    finish: 'Finalizar',
    saving: 'Guardando',
    create: 'Crear',
    password: 'Contraseña',
    pswdLower: 'contraseña',
    email: 'Email',
    emailLower: 'email',
    previous: 'Anterior',
    next: 'Siguiente',
    understood: 'Entendido',
    getBackToHome: 'Regresar al Inicio',
    pageNotExist: 'Lo sentimos, la página que visitaste no existe.',
    getBack: 'Volver',
    exit: 'Salir',
    yes: 'Sí',
    no: 'No',
    namePattern: /^([ÑñA-Za-záéíóúÁÉÍÓÚÜüàèìòù]+[\s]?)*$/,
    drivingLicence: 'Licencia de conducir *',
    noInfo: 'Sin información',
    error: {
      required: 'Ingresa tu {{input}}',
      emailFormat: 'Formato de email no corresponde',
      emailNoExist: 'Email no se encuentra registrado',
    },
    supportEmail: 'ayuda@timejobs.work',
    logout: 'Cerrar sesión',
    settings: {
      options: {
        title: 'Opciones',
        support: 'Soporte Time Jobs',
        help: 'Ayuda',
      },
      auth: {
        closeSession: 'Cerrar Sesión',
      },
    },
    determinants: {
      this: 'Este',
      this_male: 'Este',
      this_female: 'Esta',
    },
    enums: {
      operativeSystem: {
        ios: 'iOS',
        android: 'Android',
        any: 'Ambos',
      },
      vehicleDocumentKeyCodeMatchEnum: {
        carDriverLicense: '$t(global.drivingLicence)',
        carOwnershipCard: 'Tarjeta de propiedad *',
        carRegistrationCertificate: 'Permiso de circulación *',
        carRoadWorthinessTest: 'Revisión técnica CITV *',
        carInsurance: 'Seguro adicional del auto (opcional)',
        carSoat: 'SOAT *',
        mopedDriverLicense: '$t(global.drivingLicence)',
        mopedOwnershipCard: '',
        mopedRegistrationCertificate: 'Permiso de circulación *',
        mopedSoat: '',
        motorcycleDriverLicense: '$t(global.drivingLicence)',
        motorcycleOwnershipCard: 'Tarjeta de propiedad *',
        motorcycleRegistrationCertificate: 'Permiso de circulación *',
        motorcycleRoadWorthinessTest: 'Revisión técnico-mecánica *',
        motorcycleSoat: 'SOAT *',
        vanDriverLicense: '$t(global.drivingLicence)',
        vanOwnershipCard: 'Tarjeta de propiedad *',
        vanRegistrationCertificate: '',
        vanRoadWorthinessTest: 'Revisión técnica CITV *',
        vanSoat: 'SOAT *',
        furgonDriverLicense: '$t(global.drivingLicence)',
        furgonRegistrationCertificate: 'Permiso de circulación *',
      },
      extraDocsEnum: {
        vaccinationCard: 'Carnet de vacunación',
        foodHandlingCertificate: 'Certificado Manipulación de alimentos',
        epsCertificate: 'Certificado de EPS actualizado',
        pensionCertificate: 'Certificado de pensión actualizado',
        healthCardWithFoodHandling: 'Carnet de sanidad con manipulación de alimentos',
      },
    },
  },
};
