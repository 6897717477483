import LogRocket from 'logrocket';
import * as Sentry from '@sentry/nextjs';
// eslint-disable-next-line import/extensions
import { version } from './package.json';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (['production', 'staging', 'develop'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT)) {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://c102d2e2ec28462cb6a4c3a438016ea6@o519521.ingest.sentry.io/5883095',
    tracesSampleRate: 1.0,
  });

  LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET, {
    release: version,
    network: {
      requestSanitizer: (request) => {
        if (request.url.toLowerCase().indexOf('/_next/static/chunks/polyfills') !== -1) {
          return null;
        }
        return request;
      },
    },
  });
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}
